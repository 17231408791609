<div matDialogTitle>{{ 'project_select_country' | i18n }}</div>

<mat-dialog-content>
  <kdl-project-form
    [show]="['lc']"
  ></kdl-project-form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="primary"
  >{{ 'common_close' | i18n }}
  </button>
</mat-dialog-actions>
