import {Component, OnDestroy, OnInit} from '@angular/core'
import {Subscription} from 'rxjs'
import {filter, tap} from 'rxjs/operators'
import {OpenProjectService} from '../../../services/open-project.service'
import {IProject} from '../../../services/project-types'
import {
  CUSTOMER_INTERNAL_HOME_PATH,
  FACTORY_INTERNAL_HOME_PATH
} from '../../interface/routing'
import {MatMiniFabButton} from '@angular/material/button'
import {MatTooltip} from '@angular/material/tooltip'
import {RouterLink} from '@angular/router'
import {IconComponent} from '../../icon/icon.component'
import {AsyncPipe} from '@angular/common'
import {I18nPipe} from '../../../i18n/i18n.pipe'

@Component({
  selector: 'kdl-header-print-buttons',
  templateUrl: './header-print-buttons.component.html',
  styleUrls: ['./header-print-buttons.component.scss'],
  imports: [MatMiniFabButton, MatTooltip, RouterLink, IconComponent, AsyncPipe, I18nPipe]
})
export class HeaderPrintButtonsComponent implements OnInit, OnDestroy {
  public path = ''

  public customerPath = '/' + CUSTOMER_INTERNAL_HOME_PATH

  public factoryPath = '/' + FACTORY_INTERNAL_HOME_PATH

  public sub$ = new Subscription()

  constructor(
    protected openProjectService: OpenProjectService
  ) {
  }

  ngOnInit(): void {
    this.sub$ = this.openProjectService.project$.pipe(
      tap(() => this.path = ''),
      filter(Boolean)
    ).subscribe({
        next: (project: IProject) => {
          this.path = `/${project.id}/print`
        }
      }
    )
  }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe()
  }
}
