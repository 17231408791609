import {Pipe, PipeTransform} from '@angular/core'
import {Ti18nKey} from './i18n-keys'
import {I18nService} from './i18n.service'

type Ti18nTransformation = 'd' | 'l' | 'u' | 'c'

@Pipe({
  name: 'i18nAux'
})
/**
 * Aux i18n pipeline that will try to transform a string as if it was an
 * i18n-key {@link Ti18nKey}. But if it fails, it will return the string as it
 * was.
 *
 * IMPORTANT!! Do not use unless it is imperative or too complex logic.
 * Keep using {@link I18nPipe} at all possible times.
 */
export class I18nAuxPipe implements PipeTransform {
  constructor(private readonly i18nService: I18nService) {
  }

  public transform(
    text: string,
    transformation: Ti18nTransformation = 'd',
    ...replacements: string[]
  ): string {
    try {
      const result = this.i18nService
        .translate(text as Ti18nKey, transformation, ...replacements)
      return result || text
    } catch {
      return text
    }
  }
}
