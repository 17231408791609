<nav [tabPanel]="tabPanel" mat-tab-nav-bar color="primary">
  <a [routerLink]="['/settings', { outlets: { content: 'options' }}]"
     mat-tab-link
     routerLinkActive="active"
  >{{ 'settings_tab_options' | i18n }}
  </a>

  <a [routerLink]="['/settings', { outlets: { content: 'config' }}]"
     mat-tab-link
     routerLinkActive="active"
  >{{ 'settings_tab_config' | i18n }}
  </a>

  <a [routerLink]="['/settings', { outlets: { content: 'problems' }}]"
     mat-tab-link
     routerLinkActive="active"
  >{{ 'settings_tab_problems' | i18n }}
  </a>

  <a [routerLink]="['/settings', { outlets: { content: 'items' }}]"
     mat-tab-link
     routerLinkActive="active"
  >{{ 'settings_tab_object_list' | i18n }}
  </a>

  <a [routerLink]="['/settings', { outlets: { content: 'warnings' }}]"
     mat-tab-link
     routerLinkActive="active"
  >{{ 'settings_tab_warnings' | i18n }}
  </a>

  <a [routerLink]="['/settings', { outlets: { content: 'sections' }}]"
     mat-tab-link
     routerLinkActive="active"
  >{{ 'settings_tab_sections' | i18n }}
  </a>
</nav>

<mat-tab-nav-panel #tabPanel>
  <router-outlet name="content"></router-outlet>
</mat-tab-nav-panel>
