@if (currentProject?.id) {
  <div
    [class.is-saving]="autoSaveService.saving$ | async"
    class="floating-menu-holder opacity-transition">
    <div class="floating-menu-project-holder">
      <div [routerLink]="[EDIT_PROJECT_HOME_PATH, currentProject.id]"
           class="floating-menu-title">
        {{ currentProject.customer?.name }}
      </div>

      <kdl-lock [id]="currentProject.id" class="lock"></kdl-lock>
    </div>

    <div class="floating-menu-actions-holder">
      <button
        (click)="saveProject()"
        [disabled]="!changed || saving"
        color="primary"
        mat-raised-button>
        <mat-icon class="material-icons-outlined">save</mat-icon>

        @if (changed && !saving) {
          {{ 'common_save_in' | i18n }}: {{ autoSaveTimeout - timer }}s
        } @else if (!changed && !saving) {
          {{ 'common_save' | i18n }}
        } @else if (saving) {
          {{ 'common_saving' | i18n }}...
        }
      </button>

      <kdl-header-print-buttons [class.is-saving]="changed && !saving"/>

      <div class="links">
        <!-- Open in prodboard -->
        @if (prodboardUrl) {
          <a [href]="prodboardUrl" target="_blank"
          >{{ 'project_file_open_in_pb' | i18n }}
          </a>
        } @else {
          <div>{{ 'project_no_prodboard_url' | i18n }}</div>
        }

        <!-- Upload new JSON file -->
        <a [routerLink]="['prodboard', 'update', currentProject.id]"
        >{{ 'project_file_upload_new_json' | i18n }}
        </a>
      </div>
    </div>

    <div class="floating-menu-price-holder">
      @if (currentProject.pricing) {
        <!-- Pricing will only be shown if country ("lc") is selected -->
        @if (currentProject.form.lc) {
          <a tabindex="-1"
             (keydown.enter)="openPriceDialog()"
             (click)="openPriceDialog()">
            <kdl-icon icon="price-tag"></kdl-icon>
            {{ currentProject.pricing.totalCustomerPrice | number:'1.0-0':'fr' }}
            kr
          </a>
        } @else {
          <a tabindex="-1"
             (keydown.enter)="openSelectCountryDialog()"
             (click)="openSelectCountryDialog()"
          >{{ 'project_select_country' | i18n }}</a>
        }
      }
    </div>
  </div>
}

