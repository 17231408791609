@if (errorCount > 0) {
  <div>
    <button
      [routerLink]="[settingsHome, { outlets: { content: 'problems' }}]"
      color="warn"
      mat-mini-fab
    >{{ errorCount }}
    </button>
  </div>
}
