@if ((openProjectService.project$ | async) === null) {
  <div tabindex="-1"
       class="shortcut-holder"
       (keydown.enter)="openProject()"
       (click)="openProject()">
    <mat-icon class="material-icons-outlined">add</mat-icon>
    <div>{{ 'projects_new_project' | i18n }}</div>
  </div>
} @else if (!(openProjectService.project$ | async).id) {
  <div class="shortcut-holder">
    <div>{{ 'projects_creating_project' | i18n }}</div>
  </div>
}
