import {Routes} from '@angular/router'

export default [
  {
    path: '',
    loadComponent: () => import('./login/login.component')
      .then(c => c.LoginComponent)
  },
  {
    path: 'logout',
    loadComponent: () => import('./logout/logout.component')
      .then(c => c.LogoutComponent)
  }
] as Routes
