import {Component, inject} from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog'
import {MatButton} from '@angular/material/button'
import {I18nPipe} from '../../i18n/i18n.pipe'

interface IConflictDialogData {
  url: string
}

@Component({
  selector: 'kdl-conflict-dialog',
  templateUrl: './conflict-dialog.component.html',
  imports: [
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    I18nPipe
  ]
})
export class ConflictDialogComponent {
  protected data: IConflictDialogData = inject(MAT_DIALOG_DATA)

  public static open(dialog: MatDialog, data: IConflictDialogData): MatDialogRef<ConflictDialogComponent> {
    return dialog.open(ConflictDialogComponent, {
      data,
      disableClose: true,
      maxWidth: 'min(90vw, 600px)'
    })
  }
}
