import {Component, OnInit} from '@angular/core'
import {BehaviorSubject} from 'rxjs'
import {filter, first} from 'rxjs/operators'
import {CustomerStateMap} from '../../customer/customer-state-map'
import {CustomerStateLabel} from '../../customer/customer-types'
import {ITag} from '../../model/tags/types'
import {IProjectBase} from '../../services/project-types'
import {ProjectService} from '../../services/project.service'
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle
} from '@angular/material/dialog'
import {MatIcon} from '@angular/material/icon'
import {AsyncPipe, KeyValuePipe, NgOptimizedImage} from '@angular/common'
import {MatButton} from '@angular/material/button'
import {I18nPipe} from '../../i18n/i18n.pipe'

type KulladalStateSummary = Record<CustomerStateLabel, number>

interface KulladalSummary extends KulladalStateSummary {
  waiting: number
  hollow: number
}

@Component({
  selector: 'kdl-project-overview',
  templateUrl: './project-analytics.component.html',
  styleUrls: ['./project-analytics.component.scss'],
  imports: [
    MatDialogTitle,
    MatIcon,
    NgOptimizedImage,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    KeyValuePipe,
    I18nPipe,
    AsyncPipe
  ]
})
export class ProjectAnalyticsComponent implements OnInit {

  public projectsSummary$ =
    new BehaviorSubject<KulladalSummary>({} as any)

  protected readonly customerStateMap = new CustomerStateMap()

  constructor(private projectService: ProjectService) {
  }

  public ngOnInit(): void {
    this.projectService.projects$
      .pipe(
        filter(Boolean),
        first()
      )
      .subscribe({
        next: (projects: IProjectBase[]) => {
          const statesSummary: KulladalStateSummary = this.customerStateMap.labels
            .reduce((acc: KulladalStateSummary, label: CustomerStateLabel) => {
              return {...acc, [label]: this.reduceState(projects, label)}
            }, {} as KulladalStateSummary)

          this.projectsSummary$.next({
            ...statesSummary,
            waiting: this.checkWaiting(projects),
            hollow: 0
          })
        }
      })
  }

  public sorting = (): number => 0

  private reduceState(projects: IProjectBase[], state: CustomerStateLabel): number {
    return projects
      .filter((project: IProjectBase) => project.projectPhase === state)
      .length
  }

  private checkWaiting(projects: IProjectBase[]): number {
    return projects
      .filter(p => p.tags.some((t: Partial<ITag>) => t.type === 'w')).length
  }
}
