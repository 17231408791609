import {Pipe, PipeTransform} from '@angular/core'
import {Ti18nKey} from '../i18n/i18n-keys'
import {TPriceTableColumn} from '../services/project-price-table-types'

@Pipe({
  name: 'i18nProjectPricingTable'
})
/**
 * Pipeline to transform a two-string combination into a Ti18nKey item
 */
export class I18nProjectPricingTablePipe implements PipeTransform {
  transform(column: TPriceTableColumn): Ti18nKey {
    return ('project_pricing_table_column_' + column) as Ti18nKey
  }
}
