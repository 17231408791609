import {Component, DestroyRef, inject, OnInit} from '@angular/core'
import {filter} from 'rxjs/operators'
import {OpenProjectService} from '../../../services/open-project.service'
import {IProjectFile} from '../../../services/project-file-types'
import {IProject} from '../../../services/project-types'
import {AutoSaveComponent} from '../../auto-save/auto-save.component'
import {
  PriceSummaryDialogComponent
} from './price-summary-dialog/price-summary-dialog.component'
import {
  SelectCustomerCountryDialogComponent
} from './select-customer-country-dialog/select-customer-country-dialog.component'
import {takeUntilDestroyed} from '@angular/core/rxjs-interop'
import {RouterLink} from '@angular/router'
import {LockComponent} from '../../lock/lock.component'
import {MatButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {AsyncPipe, DecimalPipe} from '@angular/common'
import {
  HeaderPrintButtonsComponent
} from '../print-buttons/header-print-buttons.component'
import {IconComponent} from '../../icon/icon.component'
import {I18nPipe} from '../../../i18n/i18n.pipe'
import {EDIT_PROJECT_HOME_PATH} from '../../interface/routing'

@Component({
  selector: 'kdl-floating-menu',
  templateUrl: './floating-menu.component.html',
  styleUrls: ['./floating-menu.component.scss'],
  imports: [
    RouterLink,
    LockComponent,
    MatButton,
    MatIcon,
    HeaderPrintButtonsComponent,
    IconComponent,
    AsyncPipe,
    DecimalPipe,
    I18nPipe
  ]
})
export class FloatingMenuComponent extends AutoSaveComponent implements OnInit {

  /**
   * Current open project
   */
  currentProject: IProject | null = null

  public prodboardUrl: string

  protected readonly EDIT_PROJECT_HOME_PATH = EDIT_PROJECT_HOME_PATH

  private readonly destroyRef = inject(DestroyRef)
  private readonly openProjectService = inject(OpenProjectService)

  public ngOnInit(): void {
    super.ngOnInit()

    this.openProjectService.project$
      .pipe(takeUntilDestroyed(this.destroyRef), filter(Boolean))
      .subscribe({
        next: (project: IProject | null) => {
          this.currentProject = project
        }
      })

    this.openProjectService.projectFile$
      .pipe(takeUntilDestroyed(this.destroyRef), filter(Boolean))
      .subscribe({
        next: (file: IProjectFile) => this.prodboardUrl = file.prodboardUrl
      })
  }

  public saveProject(): void {
    this.autoSaveService.saveChanges()
  }

  public openPriceDialog(): void {
    PriceSummaryDialogComponent.open(this.dialog)
  }

  public openSelectCountryDialog(): void {
    SelectCustomerCountryDialogComponent.open(this.dialog)
  }
}
