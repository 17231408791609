{
  "name": "product-fe",
  "version": "2.0.15",
  "description": "Frontend (website) for Kulladal's Mill",
  "repository": "git@gitlab.com:kulladal/product-fe.git",
  "author": "Dario Yuste Tirados <dyuste.developer@gmail.com>",
  "license": "Apache-2.0",
  "scripts": {
    "install": "cp ./package.json src/assets",
    "ng": "ng",
    "start": "cp ./package.json ./src/assets && ng serve --ssl --host localhost.kulladal.me",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false",
    "watch": "ng test",
    "lint": "ng lint",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.0",
    "@angular/cdk": "^19.2.1",
    "@angular/common": "^19.2.0",
    "@angular/compiler": "^19.2.0",
    "@angular/core": "^19.2.0",
    "@angular/forms": "^19.2.0",
    "@angular/material": "^19.2.1",
    "@angular/platform-browser": "^19.2.0",
    "@angular/platform-browser-dynamic": "^19.2.0",
    "@angular/router": "^19.2.0",
    "core-js": "^3.41.0",
    "ngx-editor": "^18.0.0",
    "rxjs": "~7.8.2",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.0",
    "@angular/cli": "^19.2.0",
    "@angular/compiler-cli": "^19.2.0",
    "@angular/language-service": "^19.2.0",
    "@angular/material-luxon-adapter": "^19.2.1",
    "@eslint/js": "^9.21.0",
    "@kdl/kulladal-product-backend": "next",
    "@types/babel__generator": "^7",
    "@types/jasmine": "~5.1.7",
    "@types/jasminewd2": "~2.0.13",
    "@types/luxon": "^3.4.2",
    "@types/node": "^22",
    "amazon-cognito-identity-js": "^6.3.12",
    "angular-eslint": "19.2.0",
    "cross-fetch": "^4.1.0",
    "eslint": "^9.21.0",
    "globals": "^16.0.0",
    "jasmine-core": "~5.6.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-spec-reporter": "^0.0.36",
    "luxon": "^3.5.0",
    "stylelint": "^16.15.0",
    "stylelint-config-standard-scss": "^14.0.0",
    "typescript": "5.8.2",
    "typescript-eslint": "8.26.0"
  }
}
