import {
  ApplicationConfig,
  ErrorHandler,
  inject,
  LOCALE_ID,
  provideAppInitializer,
  provideZoneChangeDetection
} from '@angular/core'
import {provideRouter, withHashLocation} from '@angular/router'
import {
  provideAnimationsAsync
} from '@angular/platform-browser/animations/async'
import {registerLocaleData} from '@angular/common'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeSvExtra from '@angular/common/locales/extra/sv'
import localeFr from '@angular/common/locales/fr'
import localeSv from '@angular/common/locales/sv'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import appRoutes from './app-routes'
import {BootstrapService} from './services/bootstrap.service'
import {GlobalErrorHandler} from './application/error-handler'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {MAT_EXPANSION_PANEL_DEFAULT_OPTIONS} from '@angular/material/expansion'
import {
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS
} from '@angular/material-luxon-adapter'
import {MAT_DATE_FORMATS} from '@angular/material/core'
import {authInterceptor} from './application/auth-interceptor'
import {responseInterceptor} from './application/response-interceptor'
import {LOCAL_STORAGE, WINDOW} from './application/injection-tokens'

registerLocaleData(localeFr, 'fr', localeFrExtra)
registerLocaleData(localeSv, 'sv', localeSvExtra)

const bootstrapFactory = (bs: BootstrapService) => bs.init()

export const appConfig: ApplicationConfig = {
  providers: [
    provideAppInitializer(() => bootstrapFactory(inject(BootstrapService))),
    {provide: WINDOW, useValue: window},
    {provide: LOCAL_STORAGE, useValue: localStorage},
    {provide: LOCALE_ID, useValue: 'sv-SE'},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {
        color: 'accent', appearance: 'fill', floatLabel: 'always'
      }
    },
    {
      provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
      useValue: {expandedHeight: '75px', collapsedHeight: '75px'}
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {useUtc: true, firstDayOfWeek: 1}
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
    provideHttpClient(
      withInterceptors([authInterceptor, responseInterceptor])
    ),
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(appRoutes, withHashLocation()),
    provideAnimationsAsync()
  ]
}
