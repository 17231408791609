import {
  PriceTable,
  PriceTableRow
} from '../../../../../services/project-price-table-types'
import {
  ItemPrice,
  ProjectPricing,
  ProjectPricingItemTypes
} from '../../../../../services/project-pricing-types'
import {PriceTableCommon} from './price-table-common'
import {Ti18nKey} from '../../../../../i18n/i18n-keys'

export class PriceTableDetailedPricing {
  public static generate(pricing: ProjectPricing): PriceTable {
    const tableData: PriceTableRow[] = []

    // Total row as section even though it is just one line
    tableData.push(new PriceTableRow({
      rowType: 'section',
      name: 'project_pricing_table_section_total',
      customer: pricing.totalCustomerPrice,
      factory: pricing.totalFactoryPrice,
      costs: pricing.totalCostsPrice
    }))

    // Global rows
    tableData.push(new PriceTableRow({
      rowType: 'section',
      name: 'project_pricing_table_section_global',
      subRows: [
        // International fee (only shown if > 0)
        ...(pricing.internationalFee ?
          [new PriceTableRow({
            rowType: 'value',
            name: 'project_pricing_table_row_international_fee',
            customer: pricing.internationalFee
          })] : []),
        // All price adjustments
        ...pricing.adjustments.map(a => new PriceTableRow({
          rowType: 'value',
          name: 'project_pricing_table_row_adjustment',
          customer: a
        }))
      ]
    }))

    ProjectPricingItemTypes.forEach(type => {
      const sectionRows = pricing.itemsByType(type)
        .map((item: ItemPrice) => {
          const itemSubRows: PriceTableRow[] = []

          // Base price
          itemSubRows.push(new PriceTableRow({
            rowType: 'value',
            name: 'project_pricing_table_row_base_price',
            amount: item.quantity,
            unit: item.type === 'ctm' ? 'area' : 'unit',
            ...item.priceTypes
          }))

          // Special options (usually only for cabinets)
          itemSubRows.push(
            ...item.specialOptions.map(so =>
              new PriceTableRow({
                rowType: 'value',
                name: so.name as Ti18nKey,
                ...so.priceTypes
              }))
          )

          // Comments subsection and all its comments as sub-rows
          itemSubRows.push(PriceTableCommon.getCommentsRows(item.comments))

          // Options subsection and all its options as sub-rows
          itemSubRows.push(new PriceTableRow({
            rowType: 'subsection',
            name: 'project_pricing_table_subsection_options',
            subRows: item.options
              .map(option => {
                if (item.type === 'ctm') {
                  return new PriceTableRow({
                    rowType: 'value',
                    name: option.name,
                    amount: option.quantity,
                    unit: 'area',
                    ...option.priceTypes
                  })
                } else {
                  return new PriceTableRow({
                    rowType: 'subsection',
                    name: option.name,
                    subRows: [
                      new PriceTableRow({
                        rowType: 'value',
                        name: 'project_pricing_table_row_base_price',
                        ...option.priceTypes
                      }),
                      PriceTableCommon.getCommentsRows(option.comments)
                    ]
                  })
                }
              })
          }))

          return new PriceTableRow({
            rowType: 'item',
            name: item.name,
            subRows: itemSubRows
          })
        })

      // Section title, eg. "CABINETS"
      tableData.push(new PriceTableRow({
        rowType: 'section',
        name: `project_pricing_table_section_item_type_${type}` as Ti18nKey,
        subRows: sectionRows
      }))
    })

    // Installations section (only if checkbox is ticked)
    if (pricing.extras.isAssemblyActive) {
      tableData.push(new PriceTableRow({
        rowType: 'section',
        name: 'project_pricing_table_section_assembly',
        subRows: PriceTableCommon.getInstallationSectionRows(pricing)
      }))
    }

    // Custom color section - one line (only if color is custom)
    if (pricing.extras.hasCustomColor) {
      tableData.push(new PriceTableRow({
        rowType: 'section',
        name: 'project_pricing_table_section_customer_color',
        customer: pricing.customColorCustomerPrice,
        costs: pricing.customColorCostsPrice
      }))
    }

    return new PriceTable(
      'project_pricing_table_title_detailed',
      tableData,
      ['name', 'quantity', 'customer', 'factory', 'costs'],
      false
    )
  }
}
