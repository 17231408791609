import {Routes} from '@angular/router'
import {
  ADMIN_HOME_PATH,
  APPLIANCES_HOME_PATH,
  CUSTOMER_HOME_PATH,
  CUSTOMER_INTERNAL_HOME_PATH,
  EDIT_PROJECT_HOME_PATH,
  FACTORY_INTERNAL_HOME_PATH,
  LOGIN_HOME_PATH,
  PRODBOARD_HOME_PATH,
  PRODUCT_HOME_PATH,
  SETTINGS_HOME_PATH,
  SUPER_ADMIN_PATH,
  USER_HOME_PATH
} from './common/interface/routing'
import {authGuard, superAuthGuard} from './application/auth.guard'
import customerRouters from './customer/customer-routes'
import settingRoutes from './settings/settings-routes'
import productsRoutes from './products/products-routes'
import prodboardRoutes from './prodboard/prodboard-routes'
import customerSpecRoutes from './customer-internal/customer-spec-routes.module'
import dashboardRoutes from './dashboard/dashboard-routes'
import loginRoutes from './login/login-routes'
import editProjectRoutes from './edit-project/edit-project-routes'
import factorySpecRoutes from './factory-internal/factory-spec-routes'

/**
 * All routes for the app. Add routes here to add them globally
 */
export default [
  {
    path: SUPER_ADMIN_PATH,
    loadComponent: () => import('./z-super-admin/super-admin.component').then(c => c.SuperAdminComponent),
    canActivate: [superAuthGuard],
    title: 'Mill - Super Admin'
  },
  {
    path: PRODUCT_HOME_PATH,
    children: productsRoutes,
    canActivate: [authGuard],
    title: 'Mill - Produkter'
  },
  {
    path: CUSTOMER_INTERNAL_HOME_PATH,
    children: customerSpecRoutes,
    canActivate: [authGuard]
  },
  {
    path: FACTORY_INTERNAL_HOME_PATH,
    children: factorySpecRoutes,
    canActivate: [authGuard]
  },
  {
    path: LOGIN_HOME_PATH,
    children: loginRoutes,
    title: 'Mill - Kulladal'
  },
  {
    path: USER_HOME_PATH,
    loadComponent: () => import('./user/user/user.component')
      .then(c => c.UserComponent),
    canActivate: [authGuard],
    title: 'Mill - Användare'
  },
  {
    path: ADMIN_HOME_PATH,
    children: dashboardRoutes,
    canActivate: [authGuard],
    title: 'Mill - Kulladal'
  },
  {
    path: SETTINGS_HOME_PATH,
    children: settingRoutes,
    canActivate: [authGuard],
    title: 'Mill - Inställningar'
  },
  {
    path: APPLIANCES_HOME_PATH,
    loadComponent: () => import('./appliances/database/appliance-database.component').then(c => c.ApplianceDatabaseComponent),
    canActivate: [authGuard],
    title: 'Mill - Vitvaror'
  },
  {
    path: EDIT_PROJECT_HOME_PATH,
    children: editProjectRoutes,
    canActivate: [authGuard]
  },
  {
    path: CUSTOMER_HOME_PATH,
    children: customerRouters,
    canActivate: [authGuard],
    title: 'Mill - Kundprojekt'
  },
  {
    path: PRODBOARD_HOME_PATH,
    children: prodboardRoutes,
    canActivate: [authGuard],
    title: 'Mill - Kundprojekt'
  },
  {
    path: '**',
    redirectTo: ADMIN_HOME_PATH,
    pathMatch: 'full',
    title: 'Mill - Kulladal'
  }
] as Routes
