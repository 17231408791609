import {Pipe, PipeTransform} from '@angular/core'
import {Ti18nKey} from '../i18n/i18n-keys'
import {TQuantityUnit} from '../services/project-price-table-types'

@Pipe({
  name: 'i18nUnits'
})
/**
 * Pipeline to transform a two-string combination into a Ti18nKey item
 */
export class I18nUnitsPipe implements PipeTransform {
  transform(type: TQuantityUnit): Ti18nKey {
    return ('common_unit_' + type) as Ti18nKey
  }
}
