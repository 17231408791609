import {Routes} from '@angular/router'

export default [
  {
    path: ':id',
    loadComponent: () => import('./home/home.component')
      .then(c => c.HomeComponent),
    children: [
      {
        path: 'cabinets',
        loadComponent: () => import('./cabinets/cabinets.component')
          .then(c => c.CabinetsComponent)
      },
      {
        path: 'settings',
        loadComponent: () => import('./project-data/project-data.component')
          .then(c => c.ProjectDataComponent)
      },
      {
        path: 'warnings',
        loadComponent: () => import('./warning/edit-project-warning.component')
          .then(c => c.EditProjectWarningComponent)
      },
      {
        path: 'pb-comments',
        loadComponent: () => import('./convert-comments/convert-comments.component')
          .then(c => c.ConvertCommentsComponent)
      },
      {
        path: 'customer/:id',
        loadComponent: () => import('../customer/customer-card/customer-card.component')
          .then(c => c.CustomerCardComponent)
      },
      {
        path: 'layout',
        loadComponent: () => import('./layout/layout.component')
          .then(c => c.LayoutComponent)
      },
      {
        path: '**',
        redirectTo: 'warnings'
      }
    ]
  }
] as Routes
