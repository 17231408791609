import {animate, state, style, transition, trigger} from '@angular/animations'
import {DecimalPipe, NgClass} from '@angular/common'
import {Component, input} from '@angular/core'
import {MatIcon} from '@angular/material/icon'
import {
  PriceTableRow,
  TPriceTableColumn
} from '../../../../../services/project-price-table-types'
import {I18nPipe} from '../../../../../i18n/i18n.pipe'
import {I18nUnitsPipe} from '../../../../../pipes/i18n-units.pipe'
import {I18nAuxPipe} from '../../../../../i18n/i18n-aux.pipe'

@Component({
  selector: 'kdl-price-summary-row',
  imports: [
    DecimalPipe,
    MatIcon,
    NgClass,
    I18nPipe,
    I18nUnitsPipe,
    I18nAuxPipe
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  templateUrl: './price-row.component.html',
  styleUrl: '../price-summary-dialog.component.scss'
})
export class PriceRowComponent {

  public row = input.required<PriceTableRow>()
  public visibleColumns = input.required<TPriceTableColumn[]>()
}
