import {Routes} from '@angular/router'

export default [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () => import('./home/customer-home.component')
      .then(c => c.CustomerHomeComponent)
  },
  {
    path: 'list',
    loadComponent: () => import('./address/address.component')
      .then(c => c.AddressComponent)
  },
  {
    path: ':id',
    loadComponent: () => import('./customer-card/customer-card.component')
      .then(c => c.CustomerCardComponent)
  }
] as Routes
