import {Routes} from '@angular/router'

export default [
  {
    path: 'product-holder/:cat/:variant',
    loadComponent: () => import('./product-holder/product-holder.component')
      .then(c => c.ProductHolderComponent)
  },
  {
    path: 'product-holder/:cat',
    loadComponent: () => import('./product-holder/product-holder.component')
      .then(c => c.ProductHolderComponent)
  },
  {
    path: 'create-category/:cat',
    loadComponent: () => import('./category-home/category-home.component')
      .then(c => c.CategoryHomeComponent)
  },
  {
    path: 'create-category',
    loadComponent: () => import('./category-home/category-home.component')
      .then(c => c.CategoryHomeComponent)
  },
  {
    path: '',
    loadComponent: () => import('./home/products.component')
      .then(c => c.ProductsComponent)
  },
  {
    path: '**',
    redirectTo: ''
  }
] as Routes
