import {
  PriceTable,
  PriceTableRow
} from '../../../../../services/project-price-table-types'
import {
  ItemPrice,
  ProjectPricing,
  ProjectPricingItemTypes,
  TProjectPricingItemType
} from '../../../../../services/project-pricing-types'
import {PriceTableCommon} from './price-table-common'
import {Ti18nKey} from '../../../../../i18n/i18n-keys'

export class PriceTablePricingBySections {
  public static generate(pricing: ProjectPricing): PriceTable {
    const tableData: PriceTableRow[] = []

    // Base section
    tableData.push(new PriceTableRow({
      rowType: 'section',
      name: 'project_pricing_table_section_base',
      subRows: this.getBaseSectionRows(pricing)
    }))

    // Cabinet comments section
    tableData.push(new PriceTableRow({
      rowType: 'section',
      name: 'project_pricing_table_section_cabinet_comments',
      subRows: this.getCabinetCommentsSectionRows(pricing)
    }))

    // Factory extras, Appliances and Counter tops sections.
    // They are displayed the same way, so we use a generic method for all of
    // them.
    ProjectPricingItemTypes
      .filter(type => type !== 'c')
      .forEach(type => {
        tableData.push(new PriceTableRow({
          rowType: 'section',
          name: `project_pricing_table_section_item_type_${type}` as Ti18nKey,
          subRows: this.getItemTypeSectionRows(pricing, type)
        }))
      })

    // Installations section (only if checkbox is ticked)
    if (pricing.extras.isAssemblyActive) {
      tableData.push(new PriceTableRow({
        rowType: 'section',
        name: 'project_pricing_table_section_assembly',
        subRows: PriceTableCommon.getInstallationSectionRows(pricing)
      }))
    }

    // Custom color section - one line (only if color is custom)
    if (pricing.extras.hasCustomColor) {
      tableData.push(new PriceTableRow({
        rowType: 'section',
        name: 'project_pricing_table_section_customer_color',
        customer: pricing.customColorCustomerPrice,
        costs: pricing.customColorCostsPrice
      }))
    }

    return new PriceTable(
      'project_pricing_table_title_sections',
      tableData,
      ['name', 'customer'],
      true
    )
  }

  private static getBaseSectionRows(pricing: ProjectPricing): PriceTableRow[] {
    const baseSectionRows: PriceTableRow[] = []
    // International fee
    baseSectionRows.push(new PriceTableRow({
      rowType: 'value',
      name: 'project_pricing_table_row_international_fee',
      customer: pricing.internationalFee
    }))
    // Project adjustments pricing
    baseSectionRows.push(
      ...pricing.adjustments.map(a => new PriceTableRow({
        rowType: 'value',
        name: 'project_pricing_table_row_adjustment',
        customer: a
      }))
    )

    // For every cabinet we need it's base price and all their options.
    const cabinetRows = pricing.itemsByType('c')
      .map(cabinet => {
        const options = cabinet.options.map(option =>
          // Every option will only have its base price, no comments
          new PriceTableRow({
            rowType: 'value',
            name: option.name,
            ...option.priceTypes
          })
        )
        // Cabinet price row
        return new PriceTableRow({
          rowType: 'subsection',
          name: cabinet.name,
          subRows: [
            // Base price for Cabinet
            new PriceTableRow({
              rowType: 'value',
              name: 'project_pricing_table_row_base_price',
              ...cabinet.priceTypes
            }),
            // Cabinet special options
            ...cabinet.specialOptions.map(so =>
              new PriceTableRow({
                rowType: 'value',
                name: so.name as Ti18nKey,
                ...so.priceTypes
              })),
            // Cabinet options
            new PriceTableRow({
              rowType: 'subsection',
              name: 'project_pricing_table_subsection_options',
              subRows: options
            })
          ]
        })
      })
    baseSectionRows.push(new PriceTableRow({
      rowType: 'subsection',
      name: 'project_pricing_table_section_item_type_c',
      subRows: cabinetRows
    }))

    return baseSectionRows
  }

  private static getCabinetCommentsSectionRows(pricing: ProjectPricing): PriceTableRow[] {
    return pricing.itemsByType('c')
      .map(cabinet =>
        // Every cabinet is a subsection with all its comments, and its
        // option comments as sub-rows.
        new PriceTableRow({
          rowType: 'subsection',
          name: cabinet.name,
          subRows: [
            ...cabinet.comments.map(comment =>
              // Cabinet comment
              new PriceTableRow({
                rowType: 'value',
                name: comment.name,
                ...comment.priceTypes
              })),
            // Cabinet options comments - A subsection per cabinet option, and
            // all cabinet option comments inside it.
            ...cabinet.options.map(option => {
              return new PriceTableRow({
                rowType: 'subsection',
                name: option.name,
                // Cabinet option comments as sub-rows.
                subRows: option.comments.map(comment =>
                  new PriceTableRow({
                    rowType: 'value',
                    name: comment.name,
                    ...comment.priceTypes
                  }))
              })
            })
          ]
        }))
  }

  private static getItemTypeSectionRows(pricing: ProjectPricing, type: TProjectPricingItemType): PriceTableRow[] {
    return pricing.itemsByType(type)
      .map((item: ItemPrice) => {
        const itemSubRows: PriceTableRow[] = []

        // Base price
        itemSubRows.push(new PriceTableRow({
          rowType: 'value',
          name: 'project_pricing_table_row_base_price',
          amount: item.quantity,
          unit: item.type === 'ctm' ? 'area' : 'unit',
          ...item.priceTypes
        }))

        // Comments subsection and all its comments as sub-rows
        itemSubRows.push(PriceTableCommon.getCommentsRows(item.comments))

        // Options subsection and all its options as sub-rows
        itemSubRows.push(new PriceTableRow({
          rowType: 'subsection',
          name: 'project_pricing_table_subsection_options',
          subRows: item.options
            .map(option => {
              if (item.type === 'ctm') {
                return new PriceTableRow({
                  rowType: 'value',
                  name: option.name,
                  amount: option.quantity,
                  unit: 'area',
                  ...option.priceTypes
                })
              } else {
                return new PriceTableRow({
                  rowType: 'subsection',
                  name: option.name,
                  subRows: [
                    new PriceTableRow({
                      rowType: 'value',
                      name: 'project_pricing_table_row_base_price',
                      ...option.priceTypes
                    }),
                    PriceTableCommon.getCommentsRows(option.comments)
                  ]
                })
              }
            })
        }))

        return new PriceTableRow({
          rowType: 'item',
          name: item.name,
          subRows: itemSubRows
        })
      })
  }
}
