import {inject} from '@angular/core'
import {ActivatedRouteSnapshot, Router} from '@angular/router'
import {Observable} from 'rxjs'
import {AuthService, IAuthInfo} from '../services/auth.service'
import {EAuthState} from '../common/interface/auth'
import {first, map} from 'rxjs/operators'
import {
  CUSTOMER_HOME_PATH,
  LOGIN_HOME_PATH,
  PRODUCT_HOME_PATH,
  SETTINGS_HOME_PATH,
  USER_HOME_PATH
} from '../common/interface/routing'

// These paths are prohibited for crew
const NO_CREW_PATHS = [SETTINGS_HOME_PATH, PRODUCT_HOME_PATH]

// These are paths that Agents cannot use
const NO_AGENT_PATHS = [USER_HOME_PATH, CUSTOMER_HOME_PATH].concat(NO_CREW_PATHS)

export const superAuthGuard = (): Observable<boolean | Promise<boolean>> => {
  const authService = inject(AuthService)
  const router = inject(Router)

  return authService.authState$.pipe(
    first(),
    map((authInfo: IAuthInfo) => {
      if (authInfo.state === EAuthState.authenticated && authInfo.user) {
        return authInfo.user.email === 'dario@kulladal.com'
      }
      return router.navigate([LOGIN_HOME_PATH])
    }))
}

export const authGuard = (route: ActivatedRouteSnapshot): Observable<boolean | Promise<boolean>> => {
  const currentRoute = route.url.toString()
  const authService = inject(AuthService)
  const router = inject(Router)

  return authService.authState$.pipe(
    first(),
    map((authInfo: IAuthInfo) => {
      if (authInfo.state === EAuthState.authenticated && authInfo.user) {
        // Route anywhere for admins
        if (authService.isAdmin()) {
          return true
        }
        if (authService.isCrew() && !NO_CREW_PATHS.includes(currentRoute)) {
          return true
        }
        return authService.isAgent() && !NO_AGENT_PATHS.includes(currentRoute)
      }
      return router.navigate([LOGIN_HOME_PATH])
    }))
}
