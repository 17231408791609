import {Component, inject, OnInit, signal} from '@angular/core'
import {
  MatDialog,
  MatDialogActions,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog'
import {finalize, switchMap} from 'rxjs'
import {first, tap} from 'rxjs/operators'
import {OpenProjectService} from '../../services/open-project.service'
import {ProjectService} from '../../services/project.service'
import {IProject} from '../../services/project-types'
import {DatePipe} from '@angular/common'
import {MatButton} from '@angular/material/button'
import {I18nPipe} from '../../i18n/i18n.pipe'
import {
  LoadingIndicatorComponent
} from '../../common/loading-indicator/loading-indicator.component'
import {WINDOW} from '../../application/injection-tokens'

@Component({
  selector: 'kdl-conflict-dialog',
  templateUrl: './project-conflict-dialog.component.html',
  imports: [
    DatePipe,
    MatDialogActions,
    MatButton,
    MatDialogTitle,
    I18nPipe,
    LoadingIndicatorComponent
  ]
})
export class ProjectConflictDialogComponent implements OnInit {
  public loading$ = signal<boolean>(true)
  public newerProject: IProject

  protected window = inject(WINDOW)
  protected openProjectService = inject(OpenProjectService)
  protected projectService = inject(ProjectService)
  protected dialogRef = inject(MatDialogRef)

  public static open(dialog: MatDialog): MatDialogRef<ProjectConflictDialogComponent> {
    return dialog.open(ProjectConflictDialogComponent, {
      disableClose: true,
      maxWidth: 'min(90vw, 600px)'
    })
  }

  public ngOnInit() {
    // Recover the project that is giving a conflict, which is the same ID as
    // the one opened right now, but it must have an older version.
    this.openProjectService.project$
      .pipe(
        first(),
        switchMap((project) =>
          this.projectService.getOneProject(project.id)),
        finalize(() => this.loading$.set(false))
      )
      .subscribe(latestProject => this.newerProject = latestProject)
  }

  public forceUpdate() {
    this.loading$.set(true)

    this.openProjectService.forceProjectUpdate()
      .pipe(
        tap(() => this.dialogRef.close()),
        finalize(() => this.loading$.set(false))
      )
      .subscribe()
  }
}
