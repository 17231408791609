import {Component, OnDestroy, OnInit} from '@angular/core'
import {
  ADMIN_HOME_PATH,
  APPLIANCES_HOME_PATH,
  CUSTOMER_HOME_PATH,
  PRODUCT_HOME_PATH,
  SETTINGS_HOME_PATH,
  USER_HOME_PATH
} from '../interface/routing'
import {filter, tap} from 'rxjs/operators'
import {Subscription} from 'rxjs'
import {AuthService, IAuthInfo} from '../../services/auth.service'
import {EAuthState} from '../interface/auth'
import {MatDialog} from '@angular/material/dialog'
import {ProjectAnalyticsComponent} from '../project-analytics/project-analytics.component'
import {OpenProjectService} from '../../services/open-project.service'
import {ScreenService} from '../../services/screen.service'
import {ApplianceService} from '../../appliances/service/appliance.service'
import {MatToolbar} from '@angular/material/toolbar'
import {RouterLink} from '@angular/router'
import {AsyncPipe, NgOptimizedImage} from '@angular/common'
import {FloatingMenuComponent} from './floating-menu/floating-menu.component'
import {ShortcutsComponent} from '../shortcuts/shortcuts.component'
import {ProblemListComponent} from '../problem-list/problem-list.component'
import {MatButton, MatIconButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {I18nPipe} from '../../i18n/i18n.pipe'

@Component({
  selector: 'kdl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    MatToolbar,
    RouterLink,
    NgOptimizedImage,
    FloatingMenuComponent,
    ShortcutsComponent,
    ProblemListComponent,
    MatIconButton,
    MatIcon,
    MatButton,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    AsyncPipe,
    I18nPipe
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {

  public productPath = '/' + PRODUCT_HOME_PATH
  public userPath = '/' + USER_HOME_PATH
  public adminHomePath = '/' + ADMIN_HOME_PATH
  public settingsHomePath = '/' + SETTINGS_HOME_PATH
  public appliancesHomePath = '/' + APPLIANCES_HOME_PATH
  public customerHomePath = '/' + CUSTOMER_HOME_PATH

  // Most users have a name, all users have an email
  // Use name when we can but fall back on email
  public userName = ''

  public userEmail = ''

  private sub$ = new Subscription()

  constructor(
    public openProjectService: OpenProjectService,
    private applianceService: ApplianceService,
    public authService: AuthService,
    public screenService: ScreenService,
    private dialog: MatDialog
  ) {
  }

  public ngOnInit(): void {
    this.applianceService.setAppliances()
    this.sub$ = this.authService.authState$
      .pipe(
        tap(() => this.userName = this.userEmail = ''),
        filter((state: IAuthInfo) => state.state === EAuthState.authenticated)
      )
      .subscribe({
        next: (state: IAuthInfo) => {
          this.userName = state.user.name
          this.userEmail = state.user.email
        }
      })
  }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe()
  }

  public analytics(): void {
    this.dialog.open(ProjectAnalyticsComponent)
  }
}
