import {Routes} from '@angular/router'

export default [
  {
    path: 'home',
    loadComponent: () => import('./home/dashboard.component')
      .then(c => c.DashboardComponent)
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  }
] as Routes
