import {inject, Injectable} from '@angular/core'
import {AuthService, IAuthInfo} from './auth.service'
import {filter, switchMap} from 'rxjs/operators'
import {EAuthState} from '../common/interface/auth'
import {SettingsItemService} from './settings-item.service'
import {Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {
  private readonly authService = inject(AuthService)
  private readonly settingsItemService = inject(SettingsItemService)

  constructor() {
    // Keep listening to auth states, and when a user is logged in, then
    // get all Mill Settings
    this.authService.authState$.pipe(
      filter((authState: IAuthInfo) => authState.state === EAuthState.authenticated),
      // Here we must bootstrap the options
      switchMap(() => this.settingsItemService.bootstrap())
    ).subscribe()
  }

  public init(): Observable<boolean> {
    // First thing we ever do, checking if a user is logged in.
    return this.authService.checkLogin()
  }
}
