import {animate, state, style, transition, trigger} from '@angular/animations'
import {NgClass, NgTemplateOutlet} from '@angular/common'
import {Component, inject, OnDestroy, OnInit} from '@angular/core'
import {MatButtonModule} from '@angular/material/button'
import {
  MatDialog,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog'
import {MatIconModule} from '@angular/material/icon'
import {MatProgressSpinner} from '@angular/material/progress-spinner'
import {MatTableModule} from '@angular/material/table'
import {MatTabsModule} from '@angular/material/tabs'
import {Subscription} from 'rxjs'
import {filter} from 'rxjs/operators'
import {OpenProjectService} from '../../../../services/open-project.service'
import {
  PriceTable,
  PriceTableRow
} from '../../../../services/project-price-table-types'
import {ProjectPricing} from '../../../../services/project-pricing-types'
import {IProject} from '../../../../services/project-types'
import {PriceRowComponent} from './price-row/price-row.component'
import {
  PriceTableDetailedPricing
} from './price-tables/price-table-detailed-pricing'
import {
  PriceTablePricingBySections
} from './price-tables/price-table-pricing-by-sections'
import {I18nPipe} from '../../../../i18n/i18n.pipe'
import {
  I18nProjectPricingTablePipe
} from '../../../../pipes/i18n-project-pricing-table.pipe'

@Component({
  selector: 'kdl-price-summary-dialog',
  imports: [
    MatDialogContent,
    NgTemplateOutlet,
    MatTabsModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    PriceRowComponent,
    MatProgressSpinner,
    NgClass,
    I18nPipe,
    I18nProjectPricingTablePipe
  ],
  animations: [
    trigger('expandAnimation', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  templateUrl: './price-summary-dialog.component.html',
  styleUrl: './price-summary-dialog.component.scss'
})
export class PriceSummaryDialogComponent implements OnInit, OnDestroy {

  public pricing!: ProjectPricing

  public pricingTables: PriceTable[] = []
  public data: PriceTableRow[] = []

  private openProjectService: OpenProjectService = inject(OpenProjectService)
  private sub$: Subscription = new Subscription()

  public static open(dialog: MatDialog): MatDialogRef<PriceSummaryDialogComponent> {
    return dialog.open(PriceSummaryDialogComponent, {
      minWidth: '500px',
      maxWidth: 'min(95vw, 1000px)',
      height: '100%',
      maxHeight: 'min(90vh, 700px)'
    })
  }

  public ngOnInit(): void {
    this.sub$ = this.openProjectService.project$
      .pipe(
        filter(Boolean),
        filter(p => !!p.pricing)
      )
      .subscribe({
        next: (project: IProject) => {
          this.pricingTables = [
            PriceTablePricingBySections.generate(project.pricing),
            PriceTableDetailedPricing.generate(project.pricing)
          ]
        }
      })
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
  }
}
