<div class="header-holder mat-elevation-z6">
  <mat-toolbar class="header">
    <div class="header-logo">
      <div [routerLink]="adminHomePath" class="header-logo-holder">
        <img [ngSrc]="'assets/logo3.png'" alt="logo" class="header-logo-image"
             height="37" priority width="110">
      </div>
    </div>

    <div class="header-main-holder">
      @if ((openProjectService.project$ | async)?.id) {
        <kdl-floating-menu></kdl-floating-menu>
      }
      @if (userName) {
        <kdl-shortcuts></kdl-shortcuts>
      }
      <kdl-problem-list></kdl-problem-list>
    </div>

    <div class="header-menu-holder">
      @if (!screenService.smallScreen() && authService.isAdmin()) {
        <button (click)="analytics()" color="primary" mat-icon-button>
          <mat-icon class="material-icons-outlined header-analytics">
            analytics
          </mat-icon>
        </button>
      }

      @if (userName) {
        <button [matMenuTriggerFor]="menu" mat-button>
          <mat-icon class="material-icons-outlined">menu</mat-icon>
        </button>

        <!-- General Menu -->
        <mat-menu #menu="matMenu">
          <div mat-menu-item disabled class="header-menu-user">
            {{ userName }}
          </div>

          @if (authService.isAdmin() || authService.isCrew()) {
            <button mat-menu-item [matMenuTriggerFor]="customer">
              {{ 'menu_customers' | i18n }}
            </button>
            <button mat-menu-item [routerLink]="[userPath]">
              {{ 'menu_users' | i18n }}
            </button>
          }
          @if (authService.isAdmin()) {
            <button mat-menu-item [routerLink]="[productPath]">
              {{ 'menu_products' | i18n }}
            </button>
            <button mat-menu-item [routerLink]="[appliancesHomePath]">
              {{ 'menu_appliances' | i18n }}
            </button>
            <button mat-menu-item [routerLink]="[settingsHomePath]">
              {{ 'menu_settings' | i18n }}
            </button>
          }
          <button [routerLink]="['login', 'logout']" mat-menu-item>
            {{ 'menu_logout' | i18n }}
          </button>
        </mat-menu>

        <!-- Customers menu -->
        <mat-menu #customer>
          <button [routerLink]="[customerHomePath, 'list']" mat-menu-item>
            {{ 'menu_customers_list' | i18n }}
          </button>
          <button [routerLink]="[customerHomePath]" mat-menu-item>
            {{ 'menu_customers' | i18n }}
          </button>
        </mat-menu>
      }
    </div>
  </mat-toolbar>
</div>
