import {Routes} from '@angular/router'

export default [
  {
    path: '',
    loadComponent: () => import('./upload/prodboard-upload.component')
      .then(c => c.ProdboardUploadComponent)
  },
  {
    path: 'update/:id',
    loadComponent: () => import('./prodboard.component')
      .then(c => c.ProdboardComponent)
  }
] as Routes
