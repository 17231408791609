import {Component} from '@angular/core'
import {MatTabLink, MatTabNav, MatTabNavPanel} from '@angular/material/tabs'
import {RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router'
import {I18nPipe} from '../../i18n/i18n.pipe'

/**
 * All items share the ID property
 */
export interface ListItem {
  id: string
}

@Component({
  selector: 'kdl-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  imports: [
    MatTabNav,
    MatTabLink,
    RouterLinkActive,
    RouterLink,
    MatTabNavPanel,
    RouterOutlet,
    I18nPipe
  ]
})
export class SettingsComponent {
}
