import {Routes} from '@angular/router'
import {SettingsComponent} from './home/settings.component'
import {
  ListItemsHomeComponent
} from './list-items/home/list-items-home.component'

export default [
  {
    path: '',
    component: SettingsComponent,
    children: [
      {
        path: 'problems',
        loadComponent: () => import('./problems/problems.component')
          .then(c => c.ProblemsComponent),
        outlet: 'content'
      },
      {
        path: 'options',
        loadComponent: () => import('./options/options.component')
          .then(c => c.OptionsComponent),
        outlet: 'content'
      },
      {
        path: 'config',
        loadComponent: () => import('./config/config.component')
          .then(c => c.ConfigComponent),
        outlet: 'content'
      },
      {
        path: 'items',
        component: ListItemsHomeComponent,
        loadComponent: () => import('./list-items/home/list-items-home.component')
          .then(c => c.ListItemsHomeComponent),
        outlet: 'content'
      },
      {
        path: 'items/:type',
        loadComponent: () => import('./list-items/home/list-items-home.component')
          .then(c => c.ListItemsHomeComponent),
        outlet: 'content'
      },
      {
        path: 'warnings',
        loadComponent: () => import('./warnings/warnings.component')
          .then(c => c.WarningsComponent),
        outlet: 'content'
      },
      {
        path: 'sections',
        loadComponent: () => import('./sections/sections.component')
          .then(c => c.SectionsComponent),
        outlet: 'content'
      }
    ]
  }
] as Routes
