import {Component, Inject, OnInit} from '@angular/core'
import {VersionService} from '../../services/version.service'
import {MatSnackBar, MatSnackBarDismiss} from '@angular/material/snack-bar'
import {filter, switchMap} from 'rxjs/operators'
import version from '../../../assets/package.json'
import {WINDOW} from '../../application/injection-tokens'

@Component({
  selector: 'kdl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public version = version.version

  constructor(
    private versionService: VersionService,
    private snackBar: MatSnackBar,
    @Inject(WINDOW) private injectedWindow: Window
  ) {
  }

  public ngOnInit() {
    this.versionService.version$.pipe(
      filter((oldVersion: string) => this.version !== oldVersion),
      switchMap(() => this.snackBar.open('There is a new version', 'Update',
        {
          horizontalPosition: 'right',
          verticalPosition: 'top'
        }).afterDismissed()),
      filter((res: MatSnackBarDismiss) => res.dismissedByAction))
      .subscribe({
        next: () => {
          this.injectedWindow.location.reload()
        }
      })
  }
}
