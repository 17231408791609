<div matDialogTitle>{{ 'projects_analytics_title' | i18n }}</div>

@if (projectsSummary$ | async; as project) {
  <div class="main">
    <div class="waiting">
      <mat-icon class="material-icons-outlined" color="accent">
        pending_actions
      </mat-icon>

      {{ 'projects_analytics_waiting_for_customer' | i18n }}:
      {{ project.waiting }}
    </div>
    @for (keyValue of customerStateMap.stateNameMap | keyvalue:sorting; track $index) {
      <div class="item">
        <img [ngSrc]="keyValue.value.image" alt="icon"
             height="20"
             width="20">
        {{ keyValue.value.position + 1 }}.
        {{ keyValue.value.name }}:
        {{ project[keyValue.key] }}
      </div>
    }
  </div>
}

<mat-dialog-actions>
  <button color="primary" mat-dialog-close="" mat-flat-button>
    {{ 'common_close' | i18n }}
  </button>
</mat-dialog-actions>
